.custom-shape-divider-bottom-1686636061 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 10;
}

.custom-shape-divider-bottom-1686636061 svg {
  position: relative;
  display: block;
  width: calc(219% + 1.3px);
  height: 214px;
}

.custom-shape-divider-bottom-1686636061 .shape-fill {
  fill: #f7f7f7;
}
