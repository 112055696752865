@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.custom-shape-divider-bottom-1686636061 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 10;
}

.custom-shape-divider-bottom-1686636061 svg {
  position: relative;
  display: block;
  width: calc(219% + 1.3px);
  height: 214px;
}

.custom-shape-divider-bottom-1686636061 .shape-fill {
  fill: #f7f7f7;
}

